<template>
  <div>
      <v-container fluid class="grid-list-xl pt-3 mt-12">
    <v-row justify="center">
      <v-col cols="12" md="10" sm="10" xs="6">
        <v-card class="px-10 pa-5">
          <div>
            <h3 class="mb-3">{{$t("config.secretKey")}}</h3>
            <li>{{$t("config.apiKeyTxt")}}</li>
            <li>{{$t("config.modifiedKey")}}</li>
            <li>{{$t("config.rememberKey")}}</li>
            <li>{{$t("config.delKey")}}</li>
            <div v-if="!isShowAddSecretKey" class="mt-3 fs-18">
              <a @click="deleteAllKeyDialog = true" class="blue--text">{{$t("config.delKey")}}</a>
            </div>
          </div>
          <v-form v-if="isShowAddSecretKey" ref="userSecretKey">
            <v-text-field v-model="secretKey" :rules="userSecretKeyRules" single-line placeholder="Secret Key" required></v-text-field>
            <v-card-actions class="justify-center">
              <v-btn tile class="mb-3" color="primary white--text" @click="addSecretKey">
                {{$t("config.add")}}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" sm="10" xs="6">
        <v-card class="pa-5" :class="responsive =='mobile' ? 'px-3':'px-10'">
          <h3 class="mb-3">{{$t("config.availableAPIkeys")}}</h3>
          <div v-if="keyInfos.length == 0">{{$t("config.availableAPIkeysHint")}}</div>
          <div v-else>
            <v-data-table dense :headers="headers" :items="keyInfos" hide-default-footer>
              <template v-slot:item.tradingCategory="{ item }">
                {{ item.tradingCategory.toUpperCase() }}
              </template>
              <template v-slot:item.timestamp="{ item }">
                  {{ formatDate(item.timestamp) }}
              </template>
              <template v-slot:item.apiKey="{ item }" >
                  {{ responsive =='PC'? item.apiKey :item.apiKey.substring(0,10)+'...' }}
              </template>
              <template v-slot:item.action="{ item }">                 
                  <v-icon v-if="item.isDefault" class="primary--text" small>
                    mdi-heart
                  </v-icon>
                  <v-icon v-if="!item.isDefault" small @click="setDefaultApiKeys(item)">
                    mdi-heart-outline
                  </v-icon>
                  <v-icon class="ml-2" small @click="getApiKey(item)">
                    mdi-eye
                  </v-icon>
                  <v-icon v-if="!item.isDefault" class="ml-2" small @click="deleteApikeysByKey(item)">
                    mdi-delete
                  </v-icon>          
              </template>
            </v-data-table>
          </div>
          <v-card-actions class="justify-center">
            <v-btn tile class="my-3" color="primary white--text" @click="showAddApiKeys">
              {{ isShowAddApiKeys ? $t('config.close') : $t('config.add') }}
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col cols="12" md="10" sm="10" xs="6">
        <v-card class="px-10 pa-5" v-show="isShowAddApiKeys">
          <h3>{{$t("config.addAPIkey")}}</h3>
          <div v-html="$t('config.addAPIkeyHint')"></div>
          <div v-html="$t('config.staticPasswordNotes')" class="mt-6"></div>
          <v-form ref="key">
            <v-row>
              <v-col cols="12" md="5" sm="6" xs="3" class="mt-3">
                <h4>{{$t("config.config")}}</h4>
                <v-select v-model="key.exchangeId" class="pa-0" :items="exchanges" item-text="name" item-value="id"
                  :rules="[v => !!v || $t('config.requiredMessage.exchangeMsg')]" :placeholder="$t('config.exchange')" outlined required></v-select>
                <v-select v-model="key.tradingCategory" :items="tradingCategories" item-text="text" item-value="value"
                  class="pa-0" :rules="[v => !!v || $t('config.requiredMessage.categoryMsg')]" :placeholder="$t('config.category')" outlined required></v-select>
                <v-text-field v-model="key.description" single-line outline :placeholder="$t('config.description')" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6" offset-md="1" class="mt-3">
                <h4>{{$t("config.credentials")}}</h4>
                <v-text-field v-model="key.apiKey" single-line outlined :placeholder="$t('config.apiKey')" :rules="keyApiKeyRules"
                  required></v-text-field>
                <v-text-field v-model="key.secretKey" single-line outlined :placeholder="$t('config.secretKey')" :rules="keySecretKeyRules"
                  required></v-text-field>
                <v-text-field v-model="key.otp" single-line outlined :placeholder="$t('config.oneTimePasswordXD')"></v-text-field>
                
                <v-card-actions class="justify-end">
                  <v-btn class="mb-3" tile color="primary white--text" :loading="addApiKeysLoading" @click="addApiKeys">{{$t("config.add")}}</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="isShowAddApiKeyDialog" persistent hide-overlay width="500">
        <v-card>
          <v-card-title class="fs-18 grey lighten-2 grey darken-4--text pa-2 pl-3">
            <h3 class="black--text">{{$t("config.apiKey")}}</h3>
            <v-spacer></v-spacer>
            <v-btn light icon @click="isShowAddApiKeyDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="apiKeyView.id == null" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-card-text>
          <v-card-text v-if="apiKeyView.id != null">
            <v-row class="py-0">
              <v-col cols="6" md="3" sm="6" class="font-weight-medium">{{$t("config.exchange")}}</v-col>
              <v-col cols="6" md="9" sm="6">{{ apiKeyView.exchangeName }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-0">
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.category")}}</v-col>
              <v-col cols="6" md="8">{{ apiKeyView.tradingCategory.toUpperCase() }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.apiKey")}}</v-col>
              <v-col cols="6" md="8">
                <span class="pointer" :title="$t('common.copy')" @click="copyToClipboard(apiKeyView.apiKey)">{{ apiKeyView.apiKey }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.secretKey")}}</v-col>
              <v-col cols="6" md="8">
                <span class="pointer" :title="$t('common.copy')" @click="copyToClipboard(apiKeyView.secretKey)">{{ apiKeyView.secretKey }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.otp")}}</v-col>
              <v-col cols="6" md="8">
                <span v-if="apiKeyView.otp != null && apiKeyView.otp != ''" class="pointer" :title="$t('common.copy')"
                  @click="copyToClipboard(apiKeyView.otp)">{{ apiKeyView.otp }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.description")}}</v-col>
              <v-col cols="6" md="8">{{ apiKeyView.description }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.default")}}</v-col>
              <v-col cols="6" md="8">{{ apiKeyView.isDefault }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" md="3" class="font-weight-medium">{{$t("config.time")}}</v-col>
              <v-col cols="6" md="8">{{ formatDate(apiKeyView.timestamp) }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <!-- 删除秘钥和所有API弹窗 -->
      <v-dialog v-model="deleteAllKeyDialog" persistent hide-overlay width="500">
        <v-card>
          <v-card-title class="fs-18 grey lighten-2 grey darken-4--text pa-2 pl-3">
            <span class="black--text">{{$t("config.deleteAllKey")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="deleteAllKeyDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="deleteSecretKey">{{ $t('common.ok') }}</v-btn>
            <v-btn text small @click="deleteAllKeyDialog = false">{{ $t('common.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- 删除一对ApiKeys -->
    <v-row>
      <v-dialog v-model="deleteApiKeysDialog" persistent hide-overlay width="500">
        <v-card>
          <v-card-title class="fs-18 grey lighten-2 grey darken-4--text pa-2 pl-3">
            <span class="black--text">{{$t("config.deleteKey")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="deleteApiKeysDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title class="font-12">
            {{deleteApiKey.apiKey}}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="deleteApiKeys">{{ $t('common.ok') }}</v-btn>
            <v-btn text small @click="deleteApiKeysDialog = false">{{ $t('common.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
  <mobile-btn></mobile-btn>
  </div>
</template>
<style>
</style>
<script>
  import Vue from 'vue'
  import { mapGetters } from "vuex";
  import config from '@/config.js'
  import MobileBtn from "@/components/common/MobileBtn";
  export default {
    data() {
      return {
        isShowAddSecretKey: false, // 是否显示添加秘钥
        isShowAddApiKeys: false, // 是否显示添加ApiKeys
        addApiKeysLoading: false, // 添加ApiKeys加载中
        secretKey: null, // 要添加的秘钥
        key: {
          exchangeId: null,
          apiKey: null,
          secretKey: null,
          otp: null,
          description: null,
          tradingCategory: null
        },
        userSecretKeyRules: [
          v => !!v || (this.$t("config.requiredMessage.secretKey"))
        ],
        keyApiKeyRules: [
          v => !!v || (this.$t("config.requiredMessage.apiKey"))
        ],
        keySecretKeyRules: [
          v => !!v || (this.$t("config.requiredMessage.secretKey"))
        ],
        descriptionRules: [
          v => v && v.length <= 200 || (this.$t("config.requiredMessage.descriptionRulesHint"))
        ],
        keyInfos: [], // ApiKey集合
        headers: [{
            text: this.$t('config.exchangeName'),
            sortable: false,
            value:"exchangeName"
          },
          {
            text: this.$t('config.category'),
            sortable: false,
            value:"tradingCategory"
          },
          {
            text: this.$t('config.apiKey'),
            sortable: false,
            value:"apiKey"
          },
          {
            text: this.$t('config.description'),
            sortable: false,
            value:"description"
          },
          {
            text: this.$t('config.createTime'),
            sortable: false,
            value:"timestamp"
          },
          {
            text: this.$t('config.actions'),
            sortable: false,
            value:"action"
          }
        ],
        isShowAddApiKeyDialog: false, // ApiKey模态框
        apiKeyView: {}, // 查看ApiKey对象的模态框
        deleteAllKeyDialog: false,
        deleteApiKeysDialog: false,
        deleteApiKey: {},
      }
    },
    components: { MobileBtn },
    computed: {
      ...mapGetters(['user', 'exchanges', 'tradingCategories','responsive'])
    },
    created() {
      this.isLogin();
      this.judgeUserRole();
      this.getApiKeyInfos();
      this.hasSecretKey();
    },
    mounted() {

    },
    watch: {

    },
    methods: {
      // 是否登录
      isLogin() {
        if (!this.user.token) {
          this.$router.push({
            name: 'home'
          });
        }
      },
      // 判断角色
      judgeUserRole() {
        if (this.user != null && this.user.role != 'TRADER') {
          this.$store.dispatch('snackbarMessageHandler', this.$t("common.notTrader"));
          this.$router.push({
            name: 'home'
          });
        }
      },
      // 是否存在秘钥
      hasSecretKey() {
        let api = config.baseUrl + '/user/private/hasSecretKey';
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            if (!data.result) {
              // 没有秘钥，可以添加API Keys
              this.isShowAddSecretKey = true;
            }
          }
        }, error => {

        });
      },
      // 添加秘钥
      addSecretKey() {
        if (this.$refs.userSecretKey.validate()) {
          let api = config.baseUrl + '/user/private/addSecretKey';
          let sha256 = require("js-sha256").sha256;
          let param = {
            secretKey: sha256(this.secretKey)
          };
          this.$http.post(api, param).then(response => {
            let data = response.data;
            if (data.code == 200) {
              if (data.result) {
                // 添加成功
                this.$refs.userSecretKey.reset();
                this.isShowAddSecretKey = false;
                this.$store.dispatch('snackbarMessageHandler', (this.$t("config.snackbarMessageHandler")));
              }
            }
          }, error => {
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          });
        }
      },

      // 删除秘钥和所有API Keys
      deleteSecretKey() {
        let api = config.baseUrl + '/user/private/deleteSecretKey';
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            // 删除成功
            this.isShowAddSecretKey = true;
            this.isShowAddApiKeys = false;
            this.keyInfos = [];
            this.deleteAllKeyDialog = false;
            this.$store.dispatch('snackbarMessageHandler', data.message)
          }
        }, error => {
          this.deleteAllKeyDialog = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'))
        });
      },
      // 显示添加Api Keys的表单
      showAddApiKeys() {
        if (this.isShowAddSecretKey) {
          this.$store.dispatch('snackbarMessageHandler', (this.$t("config.snackbarMessageHandler")));
        } else {
          this.isShowAddApiKeys = !this.isShowAddApiKeys;
        }
      },
      // 添加交易所ApiKey和秘钥对
      addApiKeys() {
        if (this.$refs.key.validate()) {
          // 判断是否已经存在要添加的交易所的ApiKeys
          // for(let i = 0; i < this.keyInfos.length; i++){
          //     if (this.key.exchangeId == this.keyInfos[i].exchangeId) {
          //         // 存在就显示提示信息
          //         this.snackbarMessage = '已经存在该交易所的API Keys。';
          //         this.isShowSnackbar = true;
          //         return;
          //     }
          // }
          this.addApiKeysLoading = true;
          let api = config.baseUrl + '/key/private/addApiKeys';
          this.$http.post(api, this.key).then(response => {
            let data = response.data;
            this.addApiKeysLoading = false;
            if (data.code == 200) {
              // 添加成功
              this.isShowAddApiKeys = false;
              // this.keyInfos.push(data.result);
              this.getApiKeyInfos();
              this.$refs.key.reset();
            }
          }, error => {
            this.addApiKeysLoading = false;
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          });
        }
      },
      // 删除apikeys
      deleteApikeysByKey(key) {
        this.deleteApiKey = key;
        this.deleteApiKeysDialog = true;
      },
      // 删除一对ApiKeys
      deleteApiKeys() {
        let key = this.deleteApiKey;
        const index = this.keyInfos.indexOf(key);
        let api = config.baseUrl + '/key/private/deleteApiKeys';
        let param = {
          id: this.keyInfos[index].keyId
        };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          if (data.code == 200) {
            // 删除成功
            this.keyInfos.splice(index, 1);
            this.deleteApiKeysDialog = false;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 查询已经添加的交易所的ApiKeys
      getApiKeyInfos() {
        let api = config.baseUrl + '/key/private/getApiKeyInfos';
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.keyInfos = data.result;
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 设置默认APIKeys
      setDefaultApiKeys(key) {
        const index = this.keyInfos.indexOf(key);
        let api = config.baseUrl + '/key/private/setDefaultApiKeys';
        let param = {
          id: this.keyInfos[index].keyId
        };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          if (data.code == 200) {
            for (let i = 0; i < this.keyInfos.length; i++) {
              if (this.keyInfos[index].exchangeId == this.keyInfos[i].exchangeId &&
                this.keyInfos[index].tradingCategory == this.keyInfos[i].tradingCategory) {
                if (i == index) {
                  this.keyInfos[i].isDefault = true;
                } else {
                  this.keyInfos[i].isDefault = false;
                }
              }
            }
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 查看ApiKey
      getApiKey(key) {
        let api = config.baseUrl + '/key/private/getApiKey?id=' + key.keyId;
        this.apiKeyView = {};
        this.isShowAddApiKeyDialog = true;
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.apiKeyView = data.result;
            for (let i = 0; i < this.exchanges.length; i++) {
              if (this.apiKeyView.exchangeId == this.exchanges[i].id) {
                this.apiKeyView.exchangeName = this.exchanges[i].name;
                break;
              }
            }
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 格式化日期
      formatDate(timestamp) {
        return formatDate(timestamp, this.$i18n.locale);
      },
      // 复制到剪切板
      copyToClipboard(message) {
        this.$copyText(message).then(res => {
          // 复制成功
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
        }).catch(err => {
          // 复制失败
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
        })
      },
    },
  }
</script>
